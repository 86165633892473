import { Link } from 'gatsby'
import React, { Component } from 'react'
import Img from 'gatsby-image'
import Wysiwyg from '../../components/wysiwyg/Wysiwyg'
import Hoverable from '../../components/hoverable/Hoverable'
import './OfferingList.scss'

const OfferingListItem = ({
    index,
    pageSlug,
    slug,
    featuredMedia,
    title,
    acf,
}) => (
    <Link style={{ display: 'block' }} to={`/${pageSlug}/offerings/${slug}`}>
        <Hoverable.Container>
            <div
                className="d-non-mobile"
                style={{
                    position: 'relative',
                }}
            >
                <Hoverable.Overlay>
                    <Img
                        fluid={featuredMedia.localFile.childImageSharp.fluid}
                        alt={featuredMedia.alt_text}
                        title={featuredMedia.title}
                    />
                </Hoverable.Overlay>
                <div className="image-overlay" />
            </div>
            <h3
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />
            <Wysiwyg className="text-secondary">{acf.key_services}</Wysiwyg>
            <div className="type-link mt-4 learn-more">{`Learn more`}</div>
        </Hoverable.Container>
    </Link>
)

class OfferingList extends Component {
    render() {
        const { offerings_title, offerings, pageSlug } = this.props
        return (
            <section className="offerings container-fluid px-yd mb-yd">
                {offerings_title ? (
                    <div className="row">
                        <header className="col-12">
                            <h2
                                className="mb-yd"
                                dangerouslySetInnerHTML={{
                                    __html: offerings_title,
                                }}
                            />
                        </header>
                    </div>
                ) : null}
                <ul className="offerings-list row list-unstyled">
                    {offerings.edges.map(({ node: offeringPage }, index) => (
                        <li
                            key={offeringPage.id}
                            className="offerings-list-item col-12 col-md-4"
                        >
                            <OfferingListItem
                                index={index}
                                pageSlug={pageSlug}
                                {...offeringPage}
                            />
                        </li>
                    ))}
                </ul>
            </section>
        )
    }
}

export default OfferingList
