import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../layouts/default/layout'
import SEO from '../../components/seo'
import Hoverable from '../../components/hoverable/Hoverable'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import './offering.scss'
import { getContrastColor } from '../../utils/utils'
import Wysiwyg from '../../components/wysiwyg/Wysiwyg'
import OfferingList from '../../components/offering-list/OfferingList'


const CaseStudy = ({
    component = 'li',
    slug,
    title,
    content,
    worktypes,
    featuredMedia,
    featuredMediaMobile,
}) => (
    <Hoverable.Container
        component={component}
        className="case-studies-list-item mb-yd"
    >
        <Link
            to={`/projects/${slug}`}
            className="container-fluid"
            style={{ display: 'block' }}
        >
            <div className="row align-items-center">
                <div className="col-12 col-md-6">
                    <h3
                        className="type-subtitle"
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                    <div className="type-label text-secondary mb-4">
                        <i>
                            {worktypes
                                .map(worktype => worktype.name)
                                .join(' / ')}
                        </i>
                    </div>
                    <HTMLEllipsis
                        component="div"
                        unsafeHTML={content}
                        maxLine={3}
                        ellipsis="..."
                        basedOn="letters"
                    />
                    <div className="type-link mt-5 learn-more">{`Learn more`}</div>
                </div>
                <div className="col-12 col-md-6">
                    <Hoverable.Overlay>
                        <Img
                            className="d-mobile"
                            fluid={
                                featuredMediaMobile.localFile.childImageSharp
                                    .fluid
                            }
                            alt={featuredMedia.alt_text}
                            title={featuredMedia.title}
                        />
                        <Img
                            className="d-non-mobile"
                            fluid={
                                featuredMedia.localFile.childImageSharp.fluid
                            }
                            alt={featuredMedia.alt_text}
                            title={featuredMedia.title}
                        />
                    </Hoverable.Overlay>
                </div>
            </div>
        </Link>
    </Hoverable.Container>
)

export default ({ data: { page, offerings } }) => {
    const {
        highlight_color,
        overview_title,
        overview_description,
        services,
        case_studies,
    } = page.acf
    return (
        <Layout className="OfferingPage">
            <SEO
                yoast={page.yoast}
                title={page.title}
                description={page.content}
                dateModified={page.modified}
                datePublished={page.date}
            />
            <main>
                <header className="container-fluid px-yd mb-yd mt-yd">
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">
                            {page.acf.super_title ? (
                                <h4
                                    className="text-center"
                                    dangerouslySetInnerHTML={{
                                        __html: page.acf.super_title,
                                    }}
                                />
                            ) : null}
                            <h1
                                dangerouslySetInnerHTML={{
                                    __html: page.title,
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-8 offset-md-2">
                            <Img
                                className="featured-image d-mobile"
                                fluid={
                                    page.featuredMediaMobile.localFile
                                        .childImageSharp.fluid
                                }
                                alt={page.featuredMedia.alt_text}
                                title={page.featuredMedia.title}
                            />
                            <Img
                                className="featured-image d-non-mobile"
                                fluid={
                                    page.featuredMedia.localFile.childImageSharp
                                        .fluid
                                }
                                alt={page.featuredMedia.alt_text}
                                title={page.featuredMedia.title}
                            />
                        </div>
                    </div>
                </header>
                <section className="overview container-fluid px-yd mb-yd">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h1
                                className="type-subtitle"
                                dangerouslySetInnerHTML={{
                                    __html: overview_title,
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: overview_description,
                                }}
                            />
                        </div>
                    </div>
                </section>
                <section
                    className="services container-fluid px-yd mb-yd"
                    style={{
                        backgroundColor: `${highlight_color}`,
                        color: getContrastColor(highlight_color),
                    }}
                >
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-6">
                            <h2 className="mb-5">{`Services`}</h2>
                            <Wysiwyg>{services}</Wysiwyg>
                        </div>
                    </div>
                </section>
                <section className="case-studies mb-yd">
                    <div className="container-fluid px-yd">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="mb-5">{`Case Studies`}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid px-yd">
                        <div className="row">
                            <div className="col-12">
                                <ul className="case-studies-list list-unstyled">
                                    {case_studies
                                        ? case_studies.map(caseStudy => (
                                              <CaseStudy
                                                  key={caseStudy.slug}
                                                  {...caseStudy}
                                              />
                                          ))
                                        : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <OfferingList
                    offerings={offerings}
                    pageSlug="about" //Typically 'about'
                />
            </main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query($id: String!) {
        page: wordpressPage(id: { eq: $id }) {
            title
            content
            ...YoastPage
            featuredMedia: featured_media {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(quality:90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            featuredMediaMobile: featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1024, maxHeight: 1024 quality:90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            acf {
                super_title
                highlight_color
                overview_title
                overview_description
                services
                case_studies {
                    slug
                    title
                    content
                    worktypes {
                        name
                    }
                    featuredMedia: featured_media {
                        alt_text
                        title
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1024, maxHeight: 1024 cropFocus:CENTER) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    featuredMediaMobile: featured_media {
                        alt_text
                        title
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1024, maxHeight: 768 cropFocus:CENTER) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        offerings: allWordpressPage(
            filter: { parent_element: { slug: { eq: "offerings" } } }
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    featuredMedia: featured_media {
                        alt_text
                        title
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1024, maxHeight: 1024) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    acf {
                        key_services
                    }
                }
            }
        }
    }
`
